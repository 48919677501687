<template>
  <UDropdown
    :items="items"
    :mode="['xs', 'sm'].includes($viewport.breakpoint.value) ? 'click' : 'hover'"
    :popper="{
      placement: 'bottom-end'
    }"
  >
    <UAvatar
      v-bind="$auth.state.value.item?.user?.avatar"
      size="xs"
      @click="$emit('open')"
    />

    <template #account>
      <div class="flex flex-shrink-0">
        <UAvatar
          v-bind="$auth.state.value.item?.user?.avatar"
          size="xs"
        />
      </div>
      <div class="text-left overflow-hidden text-xs">
        <div class="font-medium truncate">
          {{ $auth.state.value.item?.user?.label }}
        </div>
        <div class="truncate">
          {{ $auth.state.value.item?.user?.role.label }}
        </div>
      </div>
    </template>
    <template #colorMode="{ item }">
      <span class="truncate">
        {{ item.label }}
      </span>
      <UIcon
        class="flex-shrink-0 h-4 w-4 ms-auto"
        :name="isDark ? 'i-heroicons-sun-20-solid' : 'i-heroicons-moon-20-solid'"
      />
    </template>
    <template #item="{ item }">
      <span
        class="truncate"
        :class="{
          'text-red-500': item.color === 'red',
        }"
      >
        {{ item.label }}
      </span>
      <UIcon
        :name="item.icon"
        class="flex-shrink-0 h-4 w-4 ms-auto"
        :class="{
          'text-red-500': item.color === 'red',
        }"
      />
    </template>
  </UDropdown>
  <ModalKeyboard
    :open="modalKeyboardOpen"
    @close="modalKeyboardOpen = false"
  />
</template>

<script lang="ts" setup>
interface Emits {
  (event: 'open'): void
}
defineEmits<Emits>()

const colorMode = useColorMode()
const $auth = useAuth()
const $confirm = useConfirm()

const modalKeyboardOpen = ref(false)

const items = computed(() => [
  [
    {
      slot: 'account'
    }
  ],
  [
    {
      label: isDark.value ? 'Светлая тема' : 'Темная тема',
      slot: 'colorMode',
      click: () => {
        isDark.value = !isDark.value
      }
    }
  ],
  [
    {
      label: 'Выйти',
      icon: 'i-heroicons-arrow-left-on-rectangle-20-solid',
      color: 'red',
      click: (): void => {
        $confirm.actions.show({
          type: 'error',
          title: 'Выход',
          description: 'Вы уверены что хотите выйти из системы?',
          buttons: [
            {
              type: false,
              label: 'Отмена',
              color: 'gray'
            },
            {
              type: true,
              label: 'Выйти',
              color: 'red'
            }
          ],
          callback: async (value: boolean) => {
            if (value) {
              const [, data] = await $auth.actions.logout()

              if (data) {
                window.location.href = '/'
              }
            }
          }
        })
      }
    }
  ]
])
const isDark = computed({
  get () {
    return colorMode.value === 'dark'
  },
  set () {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  }
})
</script>
