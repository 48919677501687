<template>
  <div
    v-for="index in 3"
    :key="index"
    class="flex py-2 sm:py-4 pr-4 sm:pr-6 justify-between"
  >
    <div class="pl-4 sm:pl-6 flex-1 space-y-1">
      <USkeleton class="w-2/3 h-5" />
      <USkeleton class="w-1/4 h-4" />
    </div>
    <USkeleton class="w-1/6 h-5" />
  </div>
</template>
