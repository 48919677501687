<template>
  <div
    v-if="loading"
    class="flex flex-col items-center justify-center h-full"
  >
    <div class="text-5xl font-semibold text-gray-900 dark:text-white">
      <span>Распил</span>
      <span class="text-primary-500">12</span>
    </div>
  </div>
  <div
    v-else
    class="flex flex-col h-full"
  >
    <Header />
    <div class="flex-1 overflow-hidden h-full">
      <slot />
    </div>
    <Confirm />
    <UNotifications>
      <template #title="{ title }">
        <span v-html="title" />
      </template>
      <template #description="{ description }">
        <span v-html="description" />
      </template>
    </UNotifications>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()
const $auth = useAuth()
const colorMode = useColorMode()
const $dictionaryWeeks = useDictionary('weekDay')
const $dictionaryWorkPlans = useDictionary('workPlans')
const $dictionaryStoks = useDictionary('stoks')
const $dictionaryPermissions = useDictionary('permissions')
const $dictionarySections = useDictionary('sections')
const $dictionaryColumns = useDictionary('columns')
const $dictionaryUsers = useDictionary('users')
const $dictionaryRoles = useDictionary('roles')
const $dictionaryManufacturerSections = useDictionary('manufacturerSections')
const $dictionaryNotificationCounts = useDictionary('notificationCounts')
const $dictionaryNotificationSections = useDictionary('notificationSections')

const loading = ref<boolean>(true)

const color = computed(() => colorMode.value === 'dark' ? '#18181b' : 'white')
const permission = computed(() => route.meta.permission)
const permissions = computed(() => route.meta.permissions as string[])

onMounted(async () => {
  gainAccess()
  await fetchAll()
})
watch(
  permission || permissions,
  () => {
    gainAccess()
  }
)

const fetchAll = async () => {
  loading.value = true

  await Promise.all([
    $dictionaryWeeks.actions.fetchItems('/weekDay'),
    $dictionaryWorkPlans.actions.fetchItems('/workPlans'),
    $dictionaryStoks.actions.fetchItems('/stocks'),
    $dictionaryPermissions.actions.fetchItems('/permissions'),
    $dictionarySections.actions.fetchItems('/sections'),
    $dictionaryColumns.actions.fetchItems('/columns'),
    $dictionaryUsers.actions.fetchItems('/users'),
    $dictionaryRoles.actions.fetchItems('/roles'),
    $dictionaryManufacturerSections.actions.fetchItems('/manufacturerSections'),
    $dictionaryNotificationCounts.actions.fetchItems('/notificationCounts'),
    $dictionaryNotificationSections.actions.fetchItems('/notificationSections')
  ])

  loading.value = false
}
const gainAccess = () => {
  if ((permission.value && !$auth.can(permission.value)) || (permissions.value && !$auth.canSome(permissions.value))) {
    return showError({
      statusCode: 403,
      message: 'Не достаточно прав доступа'
    })
  }
}

useHead({
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width,height=device-height,initial-scale=1,user-scalable=0,minimum-scale=1,maximum-scale=1'
    },
    {
      key: 'theme-color',
      name: 'theme-color',
      content: color
    }
  ],
  htmlAttrs: {
    lang: 'ru'
  },
  bodyAttrs: {
    class: 'antialiased font-sans text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-900'
  }
})
</script>

<style>
html, body, #__nuxt {
  @apply select-none overflow-hidden h-full w-screen;
}
</style>
