<template>
  <Collection
    collection="notifications"
    path="/notifications"
    :query="{
      page,
      take,
      sectionId
    }"
  >
    <template #default="{ data }">
      <UCard
        :ui="{
          rounded: 'rounded-none',
          shadow: 'shadow-none',
          base: 'flex flex-col overflow-hidden h-full',
          header: {
            padding: 'py-2'
          },
          body: {
            base: 'flex flex-col flex-1 overflow-hidden divide-y divide-gray-200 dark:divide-gray-800',
            padding: ''
          },
        }"
      >
        <template #header>
          <div class="flex items-center justify-between gap-3">
            <h3 class="flex items-center gap-3">
              <span class="text-base font-semibold text-gray-900 dark:text-white">
                Уведомления
              </span>
              <div
                v-if="getDictionaryById(unReads, 0)?.count"
                class="w-2 h-2 relative rounded-full bg-red-500"
              >
                <span class="animate-ping absolute block h-full w-full rounded-full bg-red-500 opacity-75" />
              </div>
            </h3>

            <UButton
              size="xs"
              color="gray"
              variant="ghost"
              icon="i-heroicons-x-mark-20-solid"
              @click="emit('close')"
            />
          </div>
        </template>

        <!-- <NotificationTabs
          :id="sectionId"
          :loading="data.loading"
          :un-reads="unReads"
          @selected="sectionId = $event"
        /> -->

        <div
          ref="scroll"
          class="overflow-x-hidden overflow-y-auto flex-1"
        >
          <div
            v-if="data.loading"
            class="divide-y divide-gray-200 dark:divide-gray-800"
          >
            <NotificationItemLoading />
          </div>
          <div
            v-else-if="data.items.length"
            class="divide-y divide-gray-200 dark:divide-gray-800"
          >
            <NotificationItem
              v-for="item in data.items"
              :key="item.id"
              :item="item"
              :section-id="sectionId"
              @close="$emit('close')"
              @marked="handleMarked(item.id)"
            />
          </div>
          <div
            v-else
            class="flex items-center justify-center h-full text-sm text-gray-500"
          >
            <Empty :label="getDictionaryById($dictionaryNotificationSections.state.value.items, sectionId)?.label" />
          </div>
          <div
            v-if="data.nextLoading"
            class="divide-y divide-gray-200 dark:divide-gray-800"
          >
            <NotificationItemLoading />
          </div>
        </div>

        <template
          v-if="getDictionaryById(unReads, 0)?.count"
          #footer
        >
          <UButton
            size="sm"
            color="gray"
            block
            :loading="data.loading"
            @click="handleMarked()"
          >
            Отметить все как прочитанные
          </UButton>
        </template>
      </UCard>
    </template>
  </Collection>
</template>

<script lang="ts" setup>
import type { IField } from '~/types/collection'

const emit = defineEmits(['close'])
const props = defineProps<{
  unReads: IField[]
  actions: any
}>()
const { actions } = toRefs(props)

const appConfig = useAppConfig()
const $dictionaryNotificationSections = useDictionary('notificationSections')
const $collectionNotifications = useCollection('notifications')

const scroll = ref<HTMLElement>()
const page = ref(1)
const take = ref(+appConfig.DEFAULT_LIMIT)
const sectionId = ref(0)

useInfiniteScroll(
  scroll,
  () => {
    if ($collectionNotifications.state.value.meta.currentPage === $collectionNotifications.state.value.meta.lastPage) {
      return
    }
    if (!$collectionNotifications.state.value.loading && !$collectionNotifications.state.value.nextLoading) {
      $collectionNotifications.actions.fetchNextItems('/notifications')
    }
  },
  {
    distance: 50
  }
)

const handleMarked = async (id?: string) => {
  if (id) {
    await $collectionNotifications.actions.updateItem(`/read/${id}`)
  } else {
    await $collectionNotifications.actions.updateItem('/read')
  }

  await Promise.all([
    $collectionNotifications.actions.fetchItems('/notifications'),
    actions.value.fetchItems('/notificationCounts')
  ])
}
</script>
