import type { IField } from '../types/collection'

export const getDictionaryById = (items: IField[], id: number) => {
  const item = items.find((_item: IField) => _item.id === id)

  if (!item) {
    return ''
  }

  return item
}
