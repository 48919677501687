<template>
  <div class="border-b border-gray-200 dark:border-gray-800 bg-white/75 dark:bg-gray-900/75">
    <div class="px-4 sm:px-6">
      <div class="flex items-center justify-between h-11">
        <div class="flex items-center space-x-3 sm:space-x-6">
          <UButton
            v-if="['xs', 'sm', 'md', 'lg'].includes($viewport.breakpoint.value)"
            :icon="isOpen ? 'i-heroicons-x-mark-20-solid' : 'i-heroicons-bars-3-20-solid'"
            variant="ghost"
            color="gray"
            @click="isOpen = !isOpen"
          />
          <Logo
            v-if="!['xs'].includes($viewport.breakpoint.value)"
            :model="model"
            @open="onMain"
          />
          <Menu :items="['xs', 'sm', 'md', 'lg'].includes($viewport.breakpoint.value) ? workPlan : menu" />
        </div>
        <div class="flex items-center space-x-3">
          <Notification v-if="$auth.can(PERMISSIONS.profile.notification)" />

          <UPopover>
            <template #default="{ open }">
              <UTooltip text="Сменить тему">
                <UButton
                  color="gray"
                  variant="ghost"
                  square
                  :class="[open && 'bg-gray-50 dark:bg-gray-800']"
                  @click="isOpen = false"
                >
                  <UIcon
                    name="i-heroicons-swatch-20-solid"
                    class="w-5 h-5 text-primary-500 dark:text-primary-400"
                  />
                </UButton>
              </UTooltip>
            </template>
            <template #panel>
              <ColorPicker />
            </template>
          </UPopover>

          <Profile
            @open="isOpen = false"
          />
        </div>
      </div>
    </div>

    <MobileMenu
      v-if="['xs', 'sm', 'md', 'lg'].includes($viewport.breakpoint.value) && isOpen"
      :items="menu"
      @close="isOpen = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { PERMISSIONS } from '~/constants'

const $auth = useAuth()
const route = useRoute()
const router = useRouter()
const dayjs = useDayjs()
const appConfig = useAppConfig()
const { $viewport } = useNuxtApp()

const isOpen = ref<boolean>(false)

const model = computed(() => {
  let value = ''

  if ($auth.can(PERMISSIONS.cut.view)) {
    value = 'cut'
  } else if ($auth.can(PERMISSIONS.edge.view)) {
    value = 'edge'
  } else if ($auth.can(PERMISSIONS.additive.view)) {
    value = 'additive'
  } else if ($auth.can(PERMISSIONS.build.view)) {
    value = 'build'
  } else if ($auth.can(PERMISSIONS.pack.view)) {
    value = 'pack'
  }

  return value
})
const workPlan = computed(() => {
  return [
    {
      type: 'button',
      label: 'План работы',
      icon: 'i-heroicons-document-text-20-solid',
      to: {
        name: 'dashboard',
        query: {
          sectionId: model.value === 'cut' ? 1 : undefined,
          statusId: 1,
          page: 1,
          model: model.value,
          take: appConfig.DEFAULT_LIMIT
        }
      },
      current: route.name === 'dashboard',
      click: () => {
        isOpen.value = false
      }
    }
  ]
})
const menu = computed(() => {
  const selectedDate = route.query.selectedDate ? dayjs(route.query.selectedDate).format(appConfig.FORMAT_YYYY_MM_DD) : dayjs().format(appConfig.FORMAT_YYYY_MM_DD)

  return [
    {
      type: 'button',
      label: 'Сборка фурнитуры',
      icon: 'i-heroicons-document-text-20-solid',
      to: {
        name: 'build',
        query: {
          statusId: 1,
          page: 1,
          take: appConfig.DEFAULT_LIMIT
        }
      },
      permission: PERMISSIONS.build.view,
      current: route.name === 'build'
    },
    {
      type: 'button',
      label: 'Оклейка зеркал',
      icon: 'i-heroicons-document-text-20-solid',
      to: {
        name: 'mirror',
        query: {
          statusId: 1,
          page: 1,
          take: appConfig.DEFAULT_LIMIT
        }
      },
      permission: PERMISSIONS.mirror.view,
      current: route.name === 'mirror'
    },
    {
      type: 'button',
      label: 'Переборка брака',
      icon: 'i-heroicons-document-text-20-solid',
      to: {
        name: 'marriageBulkhead',
        query: {
          statusId: 1,
          page: 1,
          take: appConfig.DEFAULT_LIMIT
        }
      },
      permission: PERMISSIONS.marriageBulkhead.view,
      current: route.name === 'marriageBulkhead'
    },
    {
      type: 'dropdown',
      label: 'Заявки',
      items: [
        [
          {
            type: 'button',
            label: 'ФБО',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'orders-fbo',
              query: {
                createdAt: selectedDate,
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.order.view,
            current: route.name === 'orders-fbo'
          },
          {
            type: 'button',
            label: 'ФБС',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'orders-fbs',
              query: {
                createdAt: selectedDate,
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.order.view,
            current: route.name === 'orders-fbs'
          },
          {
            type: 'button',
            label: 'Настройки ФБС',
            icon: 'i-heroicons-cog-8-tooth-20-solid',
            to: {
              name: 'orders-settings',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.order.view,
            current: route.name === 'orders-settings'
          }
        ]
      ],
      permissions: [
        PERMISSIONS.order.view
      ]
    },
    {
      type: 'dropdown',
      label: 'Аналитика',
      items: [
        [
          {
            type: 'button',
            label: 'Производство',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-manufacturer',
              query: {
                startDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                endDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.manufacturer.analytic,
            current: route.name === 'analytics-manufacturer'
          },
          {
            type: 'button',
            label: 'Зарплата',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-pay',
              query: {
                selectedDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD)
              }
            },
            permission: PERMISSIONS.booker.pay,
            current: route.name === 'analytics-pay'
          },
          {
            type: 'button',
            label: 'Зарплата: с/ф',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-payBuild',
              query: {
                selectedDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD)
              }
            },
            permission: PERMISSIONS.booker.pay,
            current: route.name === 'analytics-payBuild'
          },
          {
            type: 'button',
            label: 'Зарплата: о/з',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-payMirror',
              query: {
                selectedDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD)
              }
            },
            permission: PERMISSIONS.booker.pay,
            current: route.name === 'analytics-marriageBulkhead'
          },
          {
            type: 'button',
            label: 'Зарплата: п/б',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-payMarriageBulkhead',
              query: {
                selectedDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD)
              }
            },
            permission: PERMISSIONS.booker.pay,
            current: route.name === 'analytics-marriageBulkhead'
          },
          {
            type: 'button',
            label: 'Зарплата: фасады',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-payFacade',
              query: {
                selectedDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD)
              }
            },
            permission: PERMISSIONS.booker.pay,
            current: route.name === 'analytics-facade'
          },
          // {
          //   type: 'button',
          //   label: 'Склад',
          //   icon: 'i-heroicons-document-text-20-solid',
          //   to: {
          //     name: 'analytics-stock',
          //     query: {
          //       startDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
          //       endDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
          //       stocks: 1,
          //       page: 1,
          //       take: appConfig.DEFAULT_LIMIT
          //     }
          //   },
          //   permission: PERMISSIONS.stock.analytic,
          //   current: route.name === 'analytics-stock'
          // },
          {
            type: 'button',
            label: 'Р/м: распил',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-materialConsumptionCut',
              query: {
                startDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                endDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.manufacturer.analytic,
            current: route.name === 'analytics-materialConsumptionCut'
          },
          {
            type: 'button',
            label: 'Р/м: кромка',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'analytics-materialConsumptionEdge',
              query: {
                startDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                endDate: dayjs().format(appConfig.FORMAT_YYYY_MM_DD),
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.manufacturer.analytic,
            current: route.name === 'analytics-materialConsumptionEdge'
          }
        ]
      ],
      permissions: [
        PERMISSIONS.manufacturer.analytic,
        PERMISSIONS.booker.pay,
        PERMISSIONS.stock.analytic,
        PERMISSIONS.manufacturer.analytic,
        PERMISSIONS.manufacturer.analytic
      ]
    },
    {
      type: 'dropdown',
      label: 'Продукция',
      items: [
        [
          {
            type: 'button',
            label: 'ЛДСП',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'dictionary-products',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.product.view,
            current: route.name === 'dictionary-products'
          },
          {
            type: 'button',
            label: 'Расход материала',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'dictionary-sheetFormats',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.dictionary.sheetFormat.view,
            current: route.name === 'dictionary-sheetFormats'
          },
          {
            type: 'button',
            label: 'Политика цен',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'pricePolicy',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.pricePolicy.view,
            current: route.name === 'pricePolicy'
          },
          {
            type: 'button',
            label: 'Теги',
            icon: 'i-heroicons-document-text-20-solid',
            to: {
              name: 'tags',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.tag.view,
            current: route.name === 'tags'
          }
          // {
          //   type: 'button',
          //   label: 'WB',
          //   icon: 'i-heroicons-document-text-20-solid',
          //   to: {
          //     name: 'dictionary-wb',
          //     query: {
          //       page: 1,
          //       take: appConfig.DEFAULT_LIMIT
          //     }
          //   },
          //   permission: PERMISSIONS.dictionary.productWB.view,
          //   current: route.name === 'dictionary-wb'
          // }
        ]
      ],
      permissions: [
        PERMISSIONS.product.view,
        PERMISSIONS.pricePolicy.view,
        PERMISSIONS.dictionary.sheetFormat.view,
        PERMISSIONS.tag.view,
        PERMISSIONS.dictionary.productWB.view
      ]
    },
    // {
    //   type: 'dropdown',
    //   label: 'Склад',
    //   items: [
    //     [
    //       {
    //         type: 'button',
    //         label: 'ЛДСП',
    //         icon: 'i-heroicons-document-text-20-solid',
    //         to: {
    //           name: 'dictionary-stocks',
    //           query: {
    //             stock: 1,
    //             selectedDate,
    //             page: 1,
    //             take: appConfig.DEFAULT_LIMIT
    //           }
    //         },
    //         permission: PERMISSIONS.stock.view,
    //         current: route.name === 'dictionary-stocks'
    //       }
    //       // {
    //       //   type: 'button',
    //       //   label: 'ФБС',
    //       //   icon: 'i-heroicons-document-text-20-solid',
    //       //   to: {
    //       //     name: 'fbs',
    //       //     query: {
    //       //       page: 1,
    //       //       take: appConfig.DEFAULT_LIMIT
    //       //     }
    //       //   },
    //       //   permission: PERMISSIONS.fbs.view,
    //       //   current: route.name === 'fbs'
    //       // }
    //     ]
    //   ],
    //   permissions: [
    //     PERMISSIONS.stock.view
    //   ]
    // },
    {
      type: 'dropdown',
      label: 'Администрирование',
      items: [
        [
          {
            type: 'button',
            label: 'Сотрудники',
            icon: 'i-heroicons-users-20-solid',
            to: {
              name: 'users',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.user.view,
            current: route.name === 'users'
          },
          {
            type: 'button',
            label: 'Роли',
            icon: 'i-heroicons-shield-check-20-solid',
            to: {
              name: 'roles',
              query: {
                page: 1,
                take: appConfig.DEFAULT_LIMIT
              }
            },
            permission: PERMISSIONS.role.view,
            current: route.name === 'roles'
          }
        ]
      ],
      permissions: [
        PERMISSIONS.user.view,
        PERMISSIONS.role.view
      ]
    }
  ]
})

const onMain = () => {
  if (!model.value) {
    return
  }

  router.push(workPlan.value[0].to)
  isOpen.value = false
}

watch(
  () => route.name,
  () => {
    if (isOpen.value) {
      isOpen.value = false
    }
  }
)
</script>
