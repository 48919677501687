<template>
  <Dictionary
    collection="notificationCounts"
    path="/notificationCounts"
  >
    <template #default="{ items, actions }">
      <NotificationBadge
        :un-read="getDictionaryById(items, 0)?.count"
        @open="setOpen(true)"
      />
      <USlideover
        :model-value="open"
        :ui="{
          width: 'max-w-md'
        }"
        @close="setOpen(false)"
      >
        <NotificationList
          :un-reads="items"
          :actions="actions"
          @close="setOpen(false)"
        />
      </USlideover>
    </template>
  </Dictionary>
</template>

<script lang="ts" setup>
const open = ref(false)

const setOpen = (value: boolean) => {
  open.value = value
}
</script>
