<template>
  <div
    v-if="item"
    class="hover:bg-gray-50 hover:dark:bg-gray-950/50 relative"
    :class="{
      'bg-gray-100 dark:bg-gray-950': !item.asRead,
      'bg-white dark:bg-gray-900': item.asRead
    }"
  >
    <div
      v-if="!item.asRead"
      class="absolute top-0 left-0 bottom-0 bg-red-500 w-1 rounded-r-xl"
    />
    <div class="flex items-center justify-between sm:justify-end pt-2 sm:pt-4 px-4 sm:pr-6 gap-3 sm:absolute top-0 right-0">
      <div class="text-xs text-gray-500 dark:text-gray-400">
        <UTooltip
          :text="$dayjs(item.notification.createdAt).format(appConfig.FORMAT_DD_MM_YYYY_IN_HH_MM_SS)"
          :popper="{ arrow: true }"
        >
          {{ $dayjs(item.notification.createdAt).fromNow() }}
        </UTooltip>
      </div>
      <div
        v-if="!item.asRead"
        class="flex gap-1"
      >
        <UTooltip
          text="Отметить как прочитанное"
          :popper="{ arrow: true }"
        >
          <UButton
            size="2xs"
            variant="solid"
            color="gray"
            square
            icon="i-heroicons-check-20-solid"
            @click="$emit('marked')"
          />
        </UTooltip>
      </div>
    </div>
    <NuxtLink
      class="block px-4 sm:px-6 py-2 sm:py-4 group"
      :to="onPage()"
      @click="onClose"
    >
      <NotificationTemplateOrder
        v-if="item.notification?.content"
        :type="item.notification.type"
        :item="item.notification.content"
      />
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import type { IField } from '~/types/collection'

const emit = defineEmits(['marked', 'close'])
const props = withDefaults(
  defineProps<{
    item: IField
    sectionId?: number
  }>(),
  {
    sectionId: 0
  }
)
const { item } = toRefs(props)

const appConfig = useAppConfig()
const { storage } = useCity()

const onPage = () => {
  let values = {}

  if (item.value.notification.section.id === 1 && item.value.notification.content) {
    values = {
      name: 'admin-orders-id',
      params: {
        id: item.value.notification.content.id
      },
      query: {
        cityId: storage.value.id
      }
    }
  }

  return values
}
const onClose = () => {
  if (!item.value.asRead) {
    emit('marked')
  }

  emit('close')
}
</script>
